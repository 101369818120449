
import { AutenticadorService } from "@/core/services/geral/AutenticadorService";
import { Vue, Component, Watch } from "vue-property-decorator";

@Component
export default class Login extends Vue {
  public loading: boolean = false;
  esconderSenha1: boolean = true;
  esconderSenha2: boolean = true; 
  acesso: any = {
    login: "",
    senhaNova: "",
    token: ""
  };
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  service = new AutenticadorService();
  repetirSenha: string = "";

  mounted(){
    this.acesso.token = this.$route.params.token;
  }

  Confimar() {
    if(this.acesso.senhaNova != this.repetirSenha){
        this.$swal('Aviso', "As senhas informadas não são iguais!", 'warning');
    }
    else{
        this.loading = true;
        this.service.ResetarSenha(this.acesso).then(
            res => {
                this.$swal('Aviso', res.data, res.status == 200 ? 'success' : 'warning');
                this.$router.push({name:"login"})
            },
            err => {
                this.$swal('Aviso', err.response.data, err.response.status === 400 ? 'warning' : 'error');
            }
        ).finally(() => {
            this.loading = false;
        });
    }
  }
}
